<template>
  <div class="send-email">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="content">
        <div class="left">
          <div>
            <p>Developer's email</p>
            <el-form :model="form" ref="form">
              <el-form-item
                prop="email"
                :rules="[
                 { required: true, message: '请输入正确的邮箱地址'},
                  {
                    type: 'email',
                    message: '请输入正确的邮箱地址',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input v-model="form.email"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <p>Your message</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 6 }"
              resize="none"
              v-model="yourMessage"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <p>Preview</p>
          <div>
            <p>{{ yourMessage }}</p>
            <p>
              We need to add the below code snippet into every page of our
              website, just before the closing body tag.
            </p>
            <textarea v-model="code" disabled readonly ref="textarea">
            </textarea>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendEmail">发 送</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import * as email from '@/views/Email/api.js'
export default {
  name: "sendEmail",
  props: {
    codeProp: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      code: this.codeProp,
      form: {
        email: "",
      },
      yourMessage: `Hi there!
We’d like to start using LiveChat on our website and we need your help with the installation.
Thanks!`,
    };
  },
  watch: {
    codeProp: {
      handler(newValue){
        this.code = newValue
      }
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
     sendEmail() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let param = {
            subject:'need a hand with the code',
            toaddress:this.form.email,
            isattachment:0,
            istrackopen:0,
            savetemp:0,
            singleSend:0,
            content: `${this.yourMessage}
             We need to add the below code snippet into every page of our
            website, just before the closing body tag.
            ${this.code}
            `
          }
          await email.sendemail(param)
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 45% !important;
}
::v-deep .el-dialog__body {
  background-color: rgb(248, 248, 250);
  height: 300px;
  overflow: hidden;
}
.content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  input {
    width: 100%;
  }
  textarea {
    resize: none;
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: 1px solid #dcdfe6;

    &:focus {
      border: 1px solid #00a0ff;
    }
  }
  .left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    & > div {
      width: 100%;
    }
    textarea {
      height: 120px;
    }
    p {
      margin: 5px 0px;
    }
  }
  .right {
    width: 55%;
    border: 1px solid #c0c4cc;
    padding: 5px;
    border-radius: 5px;
    overflow: auto;
    height: 100%;
    p {
      margin: 5px 0px;
    }
    textarea {
      width: 100%;
      height: 180px;
      background: #eeeeee;
      color: #999999;
      border: none;
      outline: none;
      resize: none;
    }
  }
}
</style>