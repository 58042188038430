import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 上传文件
export function fileUpload(args) {
    return axios.post('file/upload', args)
}
// 文件详情
export function getFileDetail(args) {
    return axios.post('file/detail', args)
}
// 文件删除
export function deleteFile(args) {
    return axios.post('file/delete', args)
}
// 文件保存编辑
export function saveEditFile(args) {
    return axios.post('file/saveEdit', args)
}
// 文件编辑回显
export function editFile(args) {
    return axios.post('file/edit', args)
}
// 文件预览
export function viewFile(args) {
    return axios.post('file/preview', args)
}
// 获取文件共享信息
export function getShareList(args) {
    return axios.post('file/queryShareInfo', args)
}
// 文件获取共享角色
export function getShareRoleList(args) {
    return axios.post('file/getShareRole', args)
}
// 获取公用链接
export function getPublicLink(args) {
    return axios.post('file/publicLink', args)
}
// 创建公用链接
export function createPublicLink(args) {
    return axios.post('file/createPublicLink', args)
}
// 删除公用链接
export function delPublicLink(args) {
    return axios.post('file/deletePublicLink', args)
}
// 保存文件共享信息
export function saveShareInfo(args) {
    return axios.post('file/saveShare', args)
}
// 取消共享
export function cancelShareInfo(args) {
    return axios.post('file/cancelShare', args)
}
// 禁止共享
export function cannotShareInfo(args) {
    return axios.post('file/cannotShare', args)
}
// 历史版本
export function versionHistory(args) {
    return axios.post('file/versionHistory', args)
}
// 添加追随
export function addFollow(args) {
    return axios.post('file/addfollow', args)
}
// 取消追随
export function cancelFollow(args) {
    return axios.post('file/cancelFollow', args)
}
// 获取追随
export function getFollow(args) {
    return axios.post('file/following', args)
}
// 获取文档库列表
export function getQueryLibs(args) {
    return axios.post('file/queryLibs', args)
}
// 创建文档库
export function createQueryLibs(args) {
    return axios.post('file/createLib', args)
}
// 获取文档库编辑信息
export function getEditQueryLibs(args) {
    return axios.post('file/editLib', args)
}
// 文档库编辑保存
export function saveEditQueryLibs(args) {
    return axios.post('file/saveEditLib', args)
}
// 删除文档库
export function deleteQueryLibs(args) {
    return axios.post('file/deleteLib', args)
}
// 文件库添加文件
export function addFileToLibs(args) {
    return axios.post('file/file2Lib', args)
}
// 保存文件夹
export function saveFolderLibs(args) {
    return axios.post('file/saveFolder', args)
}
// 上传新版本
export function upNewVer(args) {
    return axios.post('file/uploadNewVersion', args)
}
//保存文件库管理
export function saveLibManager(data) {
    return axios.post('/file/saveLibManager', data)
}
//删除文件库管理
export function delLibManager(data) {
    return axios.post('/file/deleteLibManager', data)
}
//查询文件库管理
export function getLibManager(data) {
    return axios.post('/file/queryLibManagerInfo', data)
}

//文件-附件添加Dropbox类型文件
export function getSaveDropbox(data) {
    return axios.post('/file/saveDropbox', data)
}

//邮件发送页
export function getEmailPageInfo(data) {
    return axios.post('emailObject/getEmailPageInfo', data)
}

//获取邮件模板
export function getEmailTemplate(onlyShowMyTemplate, searchWord) {
    return axios.post('emailObject/getEmailTemplates', { onlyShowMyTemplate: onlyShowMyTemplate, searchWord: searchWord })
}

//置顶模板
export function toTopTemplate(options) {
    return axios.post('emailObject/topEmailTemplate', options)
}

//保存邮件模板
export function saveEmailTemplate(options) {
    return axios.post('emailObject/saveEmailTemplate', options)
}
//发送邮件
export function sendemail(data) {
    return axios.post('emailObject/sendEmailObject', data)
}

//获取对象列表
export function getobjectlist() {
    return axios.post('object/getAllObjectList')
}

//获取字段
export function getsize(data) {
    return axios.post('emailObject/queryFields', data)
}

//插入字段
export function insertFields(data) {
    return axios.post('emailObject/mergeField', data)
}

//收件箱
export function inbox(data) {
    return axios.post('emailObject/queryEmailList', data)
}

//邮件详情
export function emaildetail(data) {
    return axios.post('emailObject/detail', data)
}

//更改邮件状态
export function changeread(data) {
    return axios.post('emailObject/changeRead', data)
}

//删除邮件
export function deleteemail(data) {
    return axios.post('emailObject/delete', data)
}
//根据模板id和记录获取模板内容获取
export function getTemplateSubject(data) {
    return axios.post('emailObject/getTemplateBody', data)
}

//获取收件人信息
export function getReceivePeo(data) {
    return axios.post('emailObject/getShouJianRenData', data)
}

//搜索收件人
export function seachemail(data) {
    return axios.post('emailObject/searchEmail', data)
}

//上传文件
export function uploadfile(data) {
    return axios.post('file/upload', data)
}

// 查询邮件签名
export function queryEmailSign(data) {
    return axios.post('/emailObject/querySign', data)
}

// 保存邮件签名
export function saveEmailSign(data) {
    return axios.post('/emailObject/saveSign', data)
}


//更改邮件关联记录
export function changerelateid(data) {
    return axios.post('emailObject/changeRelateid', data)
}

//删除邮件关联记录
export function deleterelateid(data) {
    return axios.post('emailObject/deleteRelateid', data)
}

//获取邮件数量
export function getemailnum(data) {
    return axios.post('emailObject/queryEmailCount', data)
}


// 保存邮件短信模板文件夹
export function saveTemplateFolder(data) {
    return axios.post("/templateFolder/saveTemplateFolder", data);
}
// 查询邮件短信模板文件夹接口
export function queryTemplateFolder(data) {
    return axios.post("/templateFolder/queryTemplateFolder", data);
}
// 删除短信邮件模板文件夹
export function deleteTemplateFolder(data) {
    return axios.post("/templateFolder/deleteTemplateFolder", data);
}
// 保存邮件短信模板
export function saveTemplate(data) {
    return axios.post("/template/saveTemplate", data);
}
// 查询文件夹内模板
export function queryTemplateByFolder(data) {
    return axios.post("/template/queryTemplateByFolder", data);
}
// 删除邮件短信模板
export function deleteTemplate(data) {
    return axios.post("/template/deleteTemplate", data);
}
// 保存邮件短信模板最近查看
export function saveRecentTemplate(data) {
    return axios.post("/template/saveRecentTemplate", data);
}
// 获取邮件短信模板最近查看
export function getRecentTemplate(data) {
    return axios.post("/template/getRecentTemplate", data);
}
// 邮件模板保存图片
export function uploadMailPic(data) {
    return axios.post("/template/uploadMailPic", data);
}

// 专用模板文件夹排序接口
export function sortPrivateTemplate(data) {
    return axios.post("/template/sortPrivateTemplate", data);
}
// 根据名称查询邮件模板
export function queryTemplateByName(data) {
    return axios.post("/template/queryTemplateByName", data);
}
// 根据id获取邮件短信模板
export function queryTemplateById(data) {
    return axios.post("/template/queryTemplateById", data);
}
// 根据id获取文件夹
export function queryTemplateFolderById(data) {
    return axios.post("/templateFolder/queryTemplateFolderById", data);
}

//获取链接是否已被评价（邮件评价跳转页面用）
export function caseEvacuatedOrNot(data) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/ServiceEvaluation/caseEvacuatedOrNot`, data)
}

//邮箱点击评价链接后，查询评价设置和评价详情
export function getEvaluationSettingWithoutlogin(data){
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/ServiceEvaluation/getEvaluationSettingWithoutlogin`,data)
  }
  
  //保存服务评价（邮件）
  export function SaveServiceEvaluationWithoutlogin(data){
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/ServiceEvaluation/SaveServiceEvaluationWithoutlogin`,data)
  }

  // 查询市场活动信息
export function getScoialMarketingActivity(data) {
    return axios.post('/social/queryCampaign', data)
}

// 获取查找数据
export function getLookupData(data) {
    return axios.post('/lookup/getLookupData', data)
}

//邮件互动规则处理接口
export function massTextingEmail(data) {
    return axios.post('/emailObject/massTextingEmail', data)
}

  // 根据模板id和记录id获取模板内容
export function getEmailObjectGetTemplateBody(data) {
    return axios.post('/emailObject/getTemplateBody', data)
}