<template>
  <div class="main">
    <div class="sendCode">
      <h1>
        <svg class="icon">
          <use href="#icon-bianzu"></use>
        </svg>
        在您的网站上安装以下代码以开始使用LiveChat
      </h1>
      <p><span>1</span> 在您网站的HTML源代码的结束</p>
      <div class="code" @mouseenter.self="showCopy" @mouseleave="showCopy">
        <textarea v-model="code" disabled readonly ref="textarea"> </textarea>
        <div class="copy">复制代码</div>
        <div
          :class="{ replay: true, copyed: copyed }"
          v-show="isShowCopy"
          @click="copyCode"
        >
          <svg class="icon">
            <use :href="copyed ? '#icon-duigou1' : '#icon-fuzhi'"></use>
          </svg>
          <p>{{ copyed ? "复制完成" : "将代码复制到粘贴板" }}</p>
        </div>
      </div>
      <p><span>2</span>重新加载您的网站，Livechat应该出现在右下角。</p>
      <div class="btn-group">
        <el-button type="primary" @click="handleSendEmail"
          >将代码发送给开发人员</el-button
        >
        <el-button type="primary" @click="nextStep">已安装</el-button>
      </div>
    </div>
    <send-email :codeProp="code" ref="sendEmail"></send-email>
  </div>
</template>

<script>
import sendEmail from "@/views/liveChat/sendCode/components/sendEmail";
export default {
  name: "sendCode",
  components: {
    sendEmail,
  },
  props: {
    licenseID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      license: this.licenseID,
      isShowCopy: false,
      dialogVisible: false,
      copyed: false,
      code: "",
    };
  },
  watch: {
    licenseID: {
      handler(newValue) {
        this.license = newValue;
        (this.code =
          `<!-- Start of LiveChat (www.livechatinc.com) code -->
    <script>
        window.__lc = window.__lc || {};` +
          `window.__lc.license = ${this.license};` +
          `(function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n) } }; !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e }(window, document, [].slice))` +
          // eslint-disable-next-line no-useless-escape
          `<\/script>` +
          `<noscript><a href="https://www.livechatinc.com/chat-with/${this.license}/" rel="nofollow">Chat with us</a>, powered by <a
    href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a></noscript>
<!-- End of LiveChat code -->`)
      },
    },
  },
  methods: {
    handleSendEmail() {
      this.$refs.sendEmail.dialogVisible = true;
    },
    showCopy() {
      // alert(123123)
      if (!this.copyed) {
        this.isShowCopy = !this.isShowCopy;
      }
    },
    copyCode() {
      navigator.clipboard.writeText(this.code);
      this.copyed = true;
    },
    nextStep() {
      this.$emit("nextStep");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: calc(100% - 50px);
  height: 100%;
  background: #fff;
  margin: 20px;
  .sendCode {
    padding: 120px 200px 0px;

    h1 {
      position: relative;
      .icon {
        width: 40px;
        height: 40px;

        position: absolute;
        left: -45px;
        bottom: 0px;
      }
    }
    p {
      margin: 20px 0px;
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        color: #fff;
        background: #dedede;
        padding: 2px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }
    }
    .code {
      position: relative;
      margin-left: 30px;
      &:hover {
        cursor: pointer;
      }

      .replay {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(45, 108, 252, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        .icon {
          height: 40px;
          width: 40px;
          color: #fff;
        }
        p {
          font-size: 30px;
        }
      }
      .copyed {
        background: rgba(0, 188, 104, 0.5);
      }
      textarea {
        width: 100%;
        height: 180px;
        background: #eeeeee;
        color: #999999;
        border: none;
        outline: none;
        resize: none;
      }
      .copy {
        position: absolute;
        right: 10px;
        top: 10px;
        background: #2d6cfc;
        color: #fff;
        padding: 5px 15px;
      }
    }

    .btn-group {
      margin-left: 30px;
    }
  }
}
</style>